import { render } from "@testing-library/react";
import * as React from "react";

export default function Form() {
  var [total, setTotal] = React.useState<Data>({
    plates: 0,
    fortyFives: 0,
    thirtyFives: 0,
    twentyFives: 0,
    tens: 0,
    fives: 0,
    twoHalfs: 0,
    ones: 0,
    remainder: 0,
  });

  const [checkedSides, setCheckedSides] = React.useState(false);

  const handleChangeSides = () => {
    setCheckedSides(!checkedSides);
  };

  return (
    <>
      <div className="relative text-white grid h-screen place-items-center">
        <div className="bg-black bg-opacity-20 p-16 rounded-lg border border-fuchsia-900">
          <form
            onSubmit={(e: React.SyntheticEvent) => {
              e.preventDefault();
              const target = e.target as typeof e.target & {
                plates: { value: string };
                bar: { value: string };
              };
              let plates = target.plates.value; // typechecks!
              let bar = target.bar.value; // typechecks!
              // etc...

              setTotal(calculation(plates, bar, checkedSides));
            }}
          >
            <div>
              <label>
                Weight:
                <input
                  type="plates"
                  name="plates"
                  placeholder="Type here"
                  className="rounded-lg border border-slate-400 text-black input input-bordered input-info w-full max-w-xs"
                />
              </label>
            </div>
            <div>
              <label>
                Bar:
                <input
                  type="bar"
                  name="bar"
                  placeholder="45"
                  className="rounded-lg border border-slate-400 text-black input input-bordered input-info w-full max-w-xs"
                />
              </label>
            </div>
            <div className="py-2 form-control">
              <label className="relative inline-flex items-center mr-5 cursor-pointer">
                <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                  LBS
                </span>
                <input type="checkbox" value="" className="sr-only peer" />
                <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-2 peer-focus:ring-fuchsia-300 dark:peer-focus:ring-fuchsia-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[36px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-fuchsia-600"></div>
                <span className="ml-1 text-sm font-medium text-gray-900 dark:text-gray-300">
                  KGS
                </span>
              </label>
            </div>
            <div className="py-2 form-control">
              <label className="relative inline-flex items-center mr-5 cursor-pointer">
                <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                  1 Side
                </span>
                <input type="checkbox" value="sides" name="sides" className="sr-only peer" onChange={handleChangeSides} />
                <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-2 peer-focus:ring-fuchsia-300 dark:peer-focus:ring-fuchsia-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[60px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-fuchsia-600"></div>
                <span className="ml-1 text-sm font-medium text-gray-900 dark:text-gray-300">
                  2 Side
                </span>
              </label>
            </div>
            
            <div>
              <input
                className="rounded-xxl border shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                type="submit"
                value="Calculate"
              />
            </div>
          </form>
        </div>
        <div>
          <div className="flex justify-center">
            {Object.entries(total).map(([key, value]) => {
              if (value > 0) {
                var name;
                switch (key) {
                  case "plates":
                    name = "Sum";
                    break;
                  case "fortyFives":
                    name = "45's";
                    break;
                  case "thirtyFives":
                    name = "35's";
                    break;
                  case "twentyFives":
                    name = "25's";
                    break;
                  case "tens":
                    name = "10's";
                    break;
                  case "fives":
                    name = "5's";
                    break;
                  case "twoHalfs":
                    name = "2.5's";
                    break;
                  case "ones":
                    name = "2.5's";
                    break;
                }
                return (
                  <div className="bg-black bg-opacity-20 p-2 rounded-lg border border-fuchsia-900">
                    {name}: {value}
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
}

function calculation(plates: any, bar: any, checkedSides: boolean) {
  //plates, then bar, say total, then say how much on one side (total/2), then say kilos
  function errorCheck(against: any, num: any) {
    if (against > num) {
      //returns false if against is greater than the number input
      //console.log("false");
      return false;
    } else {
      //console.log("true");
      return true; // returns true otherwise, weight wont overflow
    }
  }

  var calcs = (+plates - +bar) / 2;
  var fortyFives = 0,
    thirtyFives = 0,
    twentyFives = 0,
    tens = 0,
    fives = 0,
    twoHalfs = 0,
    ones = 0;

  if (errorCheck(45, calcs) === true) {
    //forty five
    fortyFives = Math.floor(calcs / 45);
    calcs = calcs % 45;
  }

  if (errorCheck(35, calcs) === true) {
    //thirty five
    thirtyFives = Math.floor(calcs / 35);
    calcs = calcs % 35;
  }

  if (errorCheck(25, calcs) === true) {
    //twenty five
    twentyFives = Math.floor(calcs / 25);
    calcs = calcs % 25;
  }

  if (errorCheck(10, calcs) === true) {
    //tens
    tens = Math.floor(calcs / 10);
    calcs = calcs % 10;
  }

  if (errorCheck(5, calcs) === true) {
    //fives
    fives = Math.floor(calcs / 5);
    calcs = calcs % 5;
  }

  if (errorCheck(2.5, calcs) === true) {
    //twohalves
    twoHalfs = Math.floor(calcs / 2.5);
    calcs = calcs % 2.5;
  }

  if (errorCheck(1, calcs) === true) {
    //twohalves
    ones = Math.floor(calcs / 1);
    calcs = calcs % 1;
  }

  if(checkedSides == true){
    fortyFives *= 2;
    thirtyFives *= 2;
    twentyFives *= 2;
    tens *= 2;
    fives *= 2;
    twoHalfs *= 2;
    ones *= 2;
  }

  const total: Data = {
    plates: plates,
    fortyFives: fortyFives,
    thirtyFives: thirtyFives,
    twentyFives: twentyFives,
    tens: tens,
    fives: fives,
    twoHalfs: twoHalfs,
    ones: ones,
    remainder: calcs,
  };

  return total;
}

type Data = {
  plates: number;
  fortyFives: number;
  thirtyFives: number;
  twentyFives: number;
  tens: number;
  fives: number;
  twoHalfs: number;
  ones: number;
  remainder: number;
};
