import Form from "./scripts/Math";
import Title from "./scripts/Title";
import Love from "./scripts/Love";

//TODO: White & Black themes, kg and lb conversions work, better design, favicon and header stuff, hover prodives more info

function App() {
  return (
    <div className="text-white font-mono flex min-h-screen flex-col justify-center bg-[#0a1120] bg-[url('https://mini.codingcodax.dev/images/dark-beams.jpg')] bg-[length:86.125rem] bg-bottom bg-no-repeat p-4">
        <Title />

        <Form />

        <Love/>

      <div className="mx-auto w-screen-sm"></div>
    </div>
  );
}

export default App;
